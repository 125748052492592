import type { Locale } from '@afishauz/api/config';
import type { LinkProps as NextLinkProps } from 'next/link';
import type { AnchorHTMLAttributes } from 'react';
import { Link as IntlLink } from '../i18n/routing';

export type LinkProps = Omit<
  NextLinkProps &
    AnchorHTMLAttributes<HTMLAnchorElement> & { nativeLink?: boolean },
  'locale'
> & { locale?: Locale };

export function Link({ prefetch, nativeLink = false, ...rest }: LinkProps) {
  return nativeLink ? (
    <a {...rest} />
  ) : (
    <IntlLink {...rest} prefetch={prefetch ?? false} />
  );
}
