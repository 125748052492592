import { type VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { createPolymorphicComponent } from '../utils/create-polymorphic-component';

const box = cva([], {
  variants: {
    px: {
      none: ['px-0'],
      sm: ['px-2'],
      md: ['px-4'],
      lg: ['px-6'],
      xl: ['px-8'],
    },
    py: {
      none: ['py-0'],
      sm: ['py-2'],
      md: ['py-4'],
      lg: ['py-6'],
      xl: ['py-8'],
    },
    pt: {
      none: ['pt-0'],
      sm: ['pt-2'],
      md: ['pt-4'],
      lg: ['pt-6'],
      xl: ['pt-8'],
    },
    pb: {
      none: ['pb-0'],
      sm: ['pb-2'],
      md: ['pb-4'],
      lg: ['pb-6'],
      xl: ['pb-8'],
    },
    pl: {
      none: ['pl-0'],
      sm: ['pl-2'],
      md: ['pl-4'],
      lg: ['pl-6'],
      xl: ['pl-8'],
    },
    pr: {
      none: ['pr-0'],
      sm: ['pr-2'],
      md: ['pr-4'],
      lg: ['pr-6'],
      xl: ['pr-8'],
    },
    mx: {
      none: ['mx-0'],
      sm: ['mx-2'],
      md: ['mx-4'],
      lg: ['mx-6'],
      xl: ['mx-8'],
    },
    my: {
      none: ['my-0'],
      sm: ['my-2'],
      md: ['my-4'],
      lg: ['my-6'],
      xl: ['my-8'],
    },
    mt: {
      none: ['mt-0'],
      sm: ['mt-2'],
      md: ['mt-4'],
      lg: ['mt-6'],
      xl: ['mt-8'],
    },
    mb: {
      none: ['mb-0'],
      sm: ['mb-2'],
      md: ['mb-4'],
      lg: ['mb-6'],
      xl: ['mb-8'],
    },
    ml: {
      none: ['ml-0'],
      sm: ['ml-2'],
      md: ['ml-4'],
      lg: ['ml-6'],
      xl: ['ml-8'],
    },
    mr: {
      none: ['mr-0'],
      sm: ['mr-2'],
      md: ['mr-4'],
      lg: ['mr-6'],
      xl: ['mr-8'],
    },
  },
  defaultVariants: {
    py: 'md',
  },
});

export interface BoxProps extends VariantProps<typeof box> {}

function _Box({
  component,
  pb,
  mr,
  my,
  mb,
  mt,
  mx,
  px,
  pt,
  pr,
  pl,
  py,
  ml,
  className,
  ...rest
}: { component: any; className?: string } & BoxProps) {
  const Element = component || 'div';
  const props = {
    className: twMerge(
      box({ px, py, pb, pl, pr, pt, mt, mb, my, mx, mr, ml }),
      className,
    ),
    ...rest,
  };
  return <Element {...props} />;
}

export const Box = createPolymorphicComponent<'div', BoxProps>(_Box);
