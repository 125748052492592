import type { Locale } from '@afishauz/api/config';
import { getTashkentDate } from '@afishauz/lib/date';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import uz from 'dayjs/locale/uz-latn';
import { useCallback } from 'react';
import { useLocale } from './i18n';

interface Config {
  locale?: Locale;
}

export function useDateTranslator({ locale }: Config = {}) {
  const lang = useLocale();

  const format = useCallback(
    (date: Date, pattern: string) => {
      const loc = locale ?? lang;
      const formatted = dayjs(getTashkentDate(new Date(date)))
        .locale(loc === 'uz' ? uz : ru)
        .format(pattern)
        .toLowerCase();
      const [d, m] = pattern.split(/\s/);
      if (d === 'D' && m === 'MMMM' && loc === 'uz') {
        return formatted.replace(/\s/, '-');
      }
      return formatted;
    },
    [lang, locale],
  );

  return {
    format,
  };
}
